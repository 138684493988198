<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="info"
      :loading="loading"
      :paginate="true"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id_equipe"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Equipes</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom v-can-access="787" @click="openModal('Cadastrar')" />
          <FilterEquipe
            @selectedFilters="fetchRealizadoEquipes((filters = $event))"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" />
      </template>
      <template v-slot:[`item.nome_equipe`]="{ item }">
        {{ item.nome_equipe }}
      </template>
      <template v-slot:[`item.qtd_indicadores`]="{ item }">
        {{ item.dados.length }}
      </template>
      <template v-slot:[`item.mes_ref`]="{ item }">
        {{ `${item.mes_ref}/${item.ano_ref}` }}
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <v-btn
          icon
          class="ml-2"
          dark
          color="primary"
          @click="modalList(item.id_equipe)"
        >
          <v-icon>mdi-account-search</v-icon>
        </v-btn>
      </template>

      <!-- expanded -->

      <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
        <v-btn small text v-if="!isExpanded" @click="expand(!isExpanded)">
          expandir
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn text small v-else @click="expand(!isExpanded)">
          ocultar
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="pa-0" :colspan="headers.length">
          <ListaRealizadoEquipe :items="item.dados" />
        </td>
      </template>
      <template v-slot:[`item.regiao`]="{ item }">
        {{ item.regiao | TitleCase }}
      </template>
    </BaseTable>
    <ModalListas
      :item="listEquipe"
      :dialogColab="dialogColab"
      @closeColab="dialogColab = false"
    />
    <ModalCadastro
      :dialog="dialog"
      :labelBtn="labelBtn"
      @closeModal="dialog = false"
    />
  </div>
</template>
<script>
import BaseTable from "@/components/shared/NewBaseTable.vue";
import BaseSegmento from "@/components/shared/BaseSegmento.vue";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import ModalCadastro from "./ModalEquipes.vue";
import ModalListas from "../../../shared/ModalColaboradoresEquipe.vue";
import FilterEquipe from "./FilterEquipe.vue";
import comissao from "@/services/http/comissaoService";
import ListaRealizadoEquipe from "./ListaRealizadoEquipe.vue";

export default {
  components: {
    BaseTable,
    RegisterBottom,
    BaseSegmento,
    ModalCadastro,
    FilterEquipe,
    ListaRealizadoEquipe,
    ModalListas
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogColab: false,
      singleExpand: false,
      labelBtn: {},
      expanded: [],
      listEquipe: "",
      headers: [
        {
          text: "Segmento",
          value: "id_band"
        },
        { text: "Empresa/Equipe", value: "nome_equipe" },
        { text: "Departamento", value: "departamento" },
        { text: "QTD Indicadores", value: "qtd_indicadores", align: "center" },
        { text: "Mês Ref", value: "mes_ref" },
        { text: "Ações", value: "acoes", align: "center" }
      ],
      info: []
    };
  },
  methods: {
    openModal(label) {
      this.dialog = true;
      this.labelBtn = label;
    },
    async modalList(id_equipe) {
      this.dialogColab = true;
      this.listEquipe = id_equipe;
    },
    async fetchRealizadoEquipes(filters) {
      this.loading = true;
      let { data } = await comissao()
        .realizadoEquipe()
        .show({ per_page: -1, ...filters });
      this.loading = false;
      const obj = this.groupByCompany(data.data, "id_equipe");
      this.info = Object.values(obj);
    },
    listar(item) {
      this.realizadoEquipe = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Listar";
    },
    groupByCompany(array, key) {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || {
          id_equipe: currentValue.id_equipe,
          id_band: currentValue.id_band,
          departamento: currentValue.departamento,
          nome_equipe: currentValue.nome_equipe,
          mes_ref: currentValue.mes_ref,
          ano_ref: currentValue.ano_ref,
          dados: []
        }).dados.push(currentValue);
        return result;
      }, {});
    }
  },

  mounted() {
    if (this.filters != {}) {
      const date = new Date().toISOString().substr(0, 7);
      const [year, month] = date.split("-");
      const filter = {
        ano_ref: parseInt(year),
        mes_ref: parseInt(month)
      };
      this.fetchRealizadoEquipes(filter);
    }
    if (this.acoes) {
      this.headers.push({
        text: "Ações",
        value: "acoes",
        sortable: false,
        align: "center"
      });
    }
  }
};
</script>

<style></style>
